import React from "react"
import PropTypes from "prop-types"
import { Box, Flex, Text } from "theme-ui"
import "./layout.css"

import Header from "./header"
import Footer from "./footer"
import Image01 from "../images/rg-01.jpg"
import Image02 from "../images/rg-02.jpg"
import Image03 from "../images/rg-03.jpg"
import Image04 from "../images/rg-04.jpg"
import Image05 from "../images/rg-05.jpg"

const Layout = ({ children }) => {
  const pictureArray = [Image01, Image02, Image03, Image04, Image05]
  const randomIndex = Math.floor(Math.random() * pictureArray.length);
  const selectedPicture = pictureArray[randomIndex];
  
  return (
    <Flex sx={{ height: ["auto", "100vh"] }}>
      <Flex
        sx={{
          flexDirection: "column",
          flexBasis: ["100%", "100%", "50%"],
          px: [3, 5],
          overflow: "auto"
        }}
      >
        <Header />
        <main>{children}</main>
        <Footer />
      </Flex>
      <Box
        sx={{
          display: ["none", "none", "block"],
          flexBasis: "50%",
          backgroundColor: "background",
          backgroundImage: `url(${selectedPicture})`, 
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
        }}
      />
    </Flex>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
