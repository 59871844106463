import React from "react"
import { Box, Flex, Text } from "theme-ui"

const Footer = () => {  
  return (

    <Box as="footer" sx={{ py: 5 }}>
    <Text as="h2" variant="h2">Contact</Text>
    
    <Flex sx={{ flexDirection: ["column", "row"], mb: [4, 6] }}>
      <Text as="p" variant="body" sx={{ flexBasis: "30%" }}>To write to Rupert directly, please use the following address:</Text>
      
      <Flex sx={{ flexDirection: "column", flexBasis: "60%", ml: [0, 4] }}>
        <Text as="p" variant="body">
          F.A.O. Rupert Graves<br />
          United Agents<br />
          12-26 Lexington Street<br />
          London<br />
          W1F OLE
        </Text>
        <Text as="p" variant="body" sx={{ fontSize: 0, color: "gray", mt: 3 }}>Please note that Rupert receives a huge amount of correspondence, and it may take a while for your letter to be answered, depending on his schedule. Please include a self-addressed envelope, and a photo if you wish to have it signed.</Text>
      </Flex>
    </Flex>
    
    <Text variant="p" variant="body" sx={{ fontSize: 0 }}>
      Site designed and built by <Text as="a" variant="link" href="https://amelia.design/" target="_blank" rel="noreferrer">Amelia Lewis</Text>. Last updated October 6, 2020.
    </Text>
  </Box>
  )
}

export default Footer
