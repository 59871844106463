import React from "react"
import { Link } from "gatsby"
import { Box, Flex, Text } from "theme-ui"

const NavLink = ({ name, url, external }) => {
  return (
    <Text as="p" variant="link" sx={{ mr: 3 }}>
      {external ? <a href={url} target="_blank" rel="noreferrer">{name}</a> : <Link to={url}>{name}</Link>}
    </Text>
  )
}

const Header = () => (
  <Box as="header" sx={{ py: 4 }}>
    <Flex as="nav" sx={{ flexWrap: "wrap" }}>
      <NavLink name="Home" url="/" />
      <NavLink name="News" url="/news" />
      <NavLink name="Film & TV" url="/film-tv" />
      <NavLink name="Stage" url="/stage" />
      <NavLink name="IMDb ↬" url="https://www.imdb.com/name/nm0001291/" external={true} />
      <NavLink name="Twitter ↬" url="https://twitter.com/_RupertGraves" external={true} />
    </Flex>
  </Box>
)

export default Header
